<template>
  <div>
    <div class="deta">
      <div class="tbable">
        <el-table :data="tableData" style="width: 100%; text-align: center;" v-loading="loading" :header-cell-style="{ 'text-align': 'center' }">
          <el-table-column label="日期" width="auto" prop="JYRQ" align="center"> </el-table-column>
          <el-table-column label="检验内容" width="auto" prop="JYNR" align="center"> </el-table-column>
          <el-table-column label="状态" width="auto" prop="ZT" align="center"> </el-table-column>
          <el-table-column label="操作" align="center" width="auto">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" type="primary" class="btton" :disabled="show">打印</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-button
      class="back"
      style="width: 100px;
            height: 50px;"
      type="primary"
      @click.native="back"
      >返回
    </el-button>
    <el-button
      class="home"
      style="width: 100px;
            height: 50px;"
      type="primary"
      @click.native="home"
      >首页
    </el-button>
    <Foo></Foo>
  </div>
</template>

<script>
import qs from 'qs'
import { getTestResultList } from '@/service/api'
import Foo from '../footer/index'
export default {
  components: {
    Foo
  },
  data() {
    return {
      MZH: '',
      loading: true,
      tableData: [],
      show: false
    }
  },
  created() {
    this.MZH = this.$route.query.MZH
    this.getTestResult()
  },
  methods: {
    async getTestResult() {
      let data = {
        MZH: this.MZH
      }
      const res = await getTestResultList(data)
      console.log(res)
      if (res.data.code == 0) {
        this.loading = false
        this.$message({
          message: '查询成功',
          type: 'success'
        })
        this.tableData = res.data.data.DATASET.ROW
      } else {
        this.loading = false
        this.$message.error(res.MSG)
      }
    },
    back() {
      this.$router.back()
    },
    home() {
      this.$router.push('/home')
    },
    handleEdit(index, row) {
      console.log(index, row)
      this.show = true
      this.$message({
        message: '打印中请稍后',
        type: 'success'
      })
      let data = {
        type: 'JYDH',
        value: row.JYDH,
        reply: row.PRINTFLAG
      }
      this.ajax('http://127.0.0.1:8080/printYYLisReport', qs.stringify(data)).then(res => {})
      this.getTestResult()
      this.$message({
        message: '打印完成',
        type: 'success'
      })
      this.show = false
    },
    ajax(url, data) {
      const p = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', url, false)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
              resolve(JSON.parse(xhr.response))
            } else {
              reject(new Error('Response error'))
            }
          }
        }
        xhr.send(data)
      })
      return p
    }
  }
}
</script>
<style lang="less" scoped>
.deta {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .tbable {
    width: 90%;
  }
}
.btton {
  font-size: 20px;
}
.input_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
